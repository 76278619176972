<template>
  <div class="page-container">
    <div class="content-wrap">
    <TopNavBar
      v-bind:secondTierLoadingDone="true"/>

    <b-container>
      <b-row class="mt-4" align-h="center" >
        <b-col class="w-100">
          <b-card-header>
          </b-card-header>
          <b-card class = "list-group p-1 mt-3">
            <b-row>
            <h1 class="pl-3">{{$t('Website Check')}}</h1>
              <span class="text-muted small p-3">{{$t('You can check any website for genderable formulations')}}</span>
              <ul class="w-100 list-group">
                <li class="w-100 list-group-item">
                  <h4>
                    {{$t('URL')}}
                  </h4>
                  <b-form
                      @submit.prevent="parseAndGenderWebpage">
                    <b-input
                      id="running_text"
                      name="running_text"
                      class="bg-primary text-white"
                      :placeholder="$t('https://')"
                      v-model="urlToAnalyze"></b-input>
                    </b-form>
                  <small class="form-text text-muted"
                         v-if="!$v.urlToAnalyze.required">
                    {{$t('e.g. https://www.mobilitaet.bs.ch/gesamtverkehr/gut-zu-wissen/fussgaengerstreifen.html')}}
                  </small>
                  <div v-if="error">
                    <b-alert class="small mt-3 p-2" show variant="danger">
                      {{error}}
                    </b-alert>
                  </div>
                  <ul class="list-group list-group-horizontal p-0 border-0">
                    <li class="col-4 col-md-2 list-group-item p-0  border-0">
                        <b-button class="mr-3 mt-2 mb-2 p-2"
                                  type="button"
                                  variant="primary"
                                  v-on:click="parseAndGenderWebpage"
                                  v-if="!queryRunning">
                                  {{$t('Check')}}</b-button>
                        <b-button class="mr-3 mt-2 mb-2 p-2"
                                  type="button"
                                  variant="primary"
                                  disabled
                                  v-if="queryRunning">
                                  {{progress}} %</b-button>
                      </li>
                    <li class="col-8 col-md-10 list-group-item p-0  border-0">
                      <transition-group name="progress-bar-complete" tag="p" class="mt-0 mb-0">
                        <b-col
                        v-for="bar in progressBar"
                        :key="bar"
                        class="progress-bar-item mt-0">
                          <b-card
                          class="m-0 p-0 border-light"
                          >
                            <div class="progress mt-0">
                              <div class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                              role="progressbar"
                              :style="'width: ' + progress + '%'" :aria-valuenow="progress" aria-valuemin="0"
                              aria-valuemax="100"></div>
                            </div>
                          </b-card>
                        </b-col>
                      </transition-group>
                    </li>
                    </ul>
                </li>
                <li class="w-100 list-group-item active">
                  <h4 class="">
                    {{$t('genderable terms')}}
                  </h4>
                     <span class="w-100 d-inline-block text-dark small bg-white fixed-hight pt-0 pb-0" v-html="fixedWithUrls"></span>
          <div v-if="tooLong">
            <b-alert show variant="warning"  v-html="$t('Only 500 characters are processed. For longer texts please use the word add-in.')">
            </b-alert>
          </div>
                </li>
                <div class="text-muted small mt-3">{{$t('Here we analyse the first 5\'000 characters of the specified webpage')}}</div>
                <div class="text-muted small">{{$t('if you want a monthly report for all pages of your webpresence, you might be interested in our commercial offer')}}: <a href="https://genderapp.org/commercial">{{$t('Commercial Use')}}
</a></div>
            </ul>
            </b-row>
          </b-card>

          <div v-if="success">
            <b-alert show variant="success">
              {{success}}
            </b-alert>
          </div>
        </b-col>
      </b-row>
<!--register modal-->
      <div>
      <b-modal hide-footer ref="registerModal" v-bind:title="$t('you are not logged in')">
        <div class="d-block text-center">
          <small class="text-muted">{{$t('Website check only works while logged in')}}</small>
          <h3 class="pt-4">{{$t('wanna create an account?')}}</h3>
        </div>
        <router-link :to="{name: 'auth.register'}" class="mt-2 btn btn-outline-success btn-block">{{$t("Go to register")}}</router-link>
        <router-link :to="{name: 'auth.login'}" class="mt-2 btn btn-outline-info btn-block">{{$t("Go to login")}}</router-link>
        <b-button block @click="hideRegisterModal" class="mt-2 btn btn-outline-danger btn-block">{{$t("Cancel")}}</b-button>
      </b-modal>
    </div>
    </b-container>
  </div>
    <GenderAppFooter/>
  </div>
</template>

<script>
import TopNavBar from '../../components/TopNavBar'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { DictionaryEntryService } from '@/services/dictionaryEntryService'
import { modalMixin } from '@/mixins/modalMixin'
import GenderAppFooter from '../../components/Footer'
import { DOMAIN } from '@/env'

export default {
  name: 'WebsiteCheck',
  // insert the following code for vue-meta to work
  metaInfo () {
    return {
      title: this.title,
      meta: [
        {
          name: 'description',
          vmid: 'description',
          content: this.description
        },
        {
          property: 'og:title',
          vmid: 'og:title',
          content: this.title
        },
        {
          property: 'og:description',
          vmid: 'og:description',
          content: this.description
        },
        {
          property: 'og:url',
          vmid: 'og:url',
          content: this.canonicalUrl
        },
        {
          name: 'twitter:title',
          vmid: 'twitter:title',
          content: this.title
        },
        {
          name: 'twitter:description',
          vmid: 'twitter:description',
          content: this.description
        }
      ],
      link: [
        { rel: 'canonical', href: this.canonicalUrl }
      ]
    }
  },
  data () {
    return {
      queries: [],
      progress: 0,
      queryRunning: false,
      urlToAnalyze: '',
      genderableWords: '',
      fixedWithUrls: '',
      lektoratWaiting: 0,
      lektoratWorking: 0,
      error: '',
      success: '',
      text_cache: '',
      title: 'Website-Check · gender app',
      description: 'Analysiere Webseiten auf Wörter, die gegendert werden wollen',
      canonicalUrl: 'https://genderapp.org/website-check'
    }
  },
  computed: {
    tooLong: function () {
      return this.urlToAnalyze.length >= 500
    },
    urlToAnalyzeTrunc: function () {
      const re = new RegExp('^(?:(?:http[s]?|ftp):\\/)?\\/?(?:[^:\\/\\s]+?\\.)*([^:\\/\\s]+\\.[^:\\/\\s]+)')
      const [, second] = re.exec(this.urlToAnalyze)
      return (second.length > 20) ? second.substr(0, 20 - 1) + '...' : second
    },
    progressBar: function () {
      return (this.queryRunning) ? ['progressbar'] : []
    }
  },
  mixins: [validationMixin, modalMixin],
  validations: {
    urlToAnalyze: {
      required
    }
  },
  methods: {

    cleanUpString (inputString, allowedCharacters = '[^a-zA-Z0-9öäüÖÄÜ\\s\\,\\.\\;\\n\\-\\?\\!\\*\\:\\/\\_]') {
      const regex = new RegExp('[^' + allowedCharacters + ']', 'g')
      return inputString.replace(regex, ' ')
    },
    sendLektorat: async function () {
      if (this.lektoratWaiting > 0) return Promise.resolve('Too many cooks')
      this.lektoratWaiting++
      await this.waitForSilence(0.1)
      this.lektoratWaiting--
      this.lektoratWorking++
      if (!this.urlToAnalyze.toString().trim()) {
        this.genderableWords = ''
        this.lektoratWorking--
        return Promise.resolve('empty paragraph')
      }
      await this.parseAndGenderWebpage()
      this.lektoratWorking--
      return Promise.resolve('ACK')
    },
    waitForSilence: async function (secs) {
      let noOfSecsWeWait = 0.2
      if (secs) {
        noOfSecsWeWait = secs
      }
      let disturbed = true
      while (disturbed) {
        disturbed = false
        await wait(noOfSecsWeWait * 1000)
        if (this.text_cache !== this.urlToAnalyze) {
          this.text_cache = this.urlToAnalyze
          disturbed = true
        }
      }
      return Promise.resolve('got it')
    },
    parseAndGenderWebpage: async function () {
      if (this.queryRunning) return
      this.error = ''
      this.genderableWords = ''
      this.fixedWithUrls = ''
      if (!this.urlToAnalyze) {
        this.error = this.$t('Please enter an URL')
        return
      }
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.error = this.$t('Please check above error(s).')
      } else {
        if (!this.isLoggedIn()) {
          this.showRegistrationModal()
          return
        }
        // eslint-disable-next-line no-control-regex
        const regex = new RegExp('https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()!@:%_\\+.~#?&\\/\\/=]*)')
        if (!regex.test(this.urlToAnalyze)) {
          this.error = this.$t('please enter valid URL (starting with https:// or http://')
          return
        }
        if (this.urlToAnalyze.includes('genderapp.org')) {
          this.error = this.$t('very funny')
        }
        this.progress = 0
        this.queryRunning = true
        const toSend = this.urlToAnalyze.substring(0, 500)
        let response
        try {
          response = await DictionaryEntryService.parseWebpage(toSend)
        } catch (err) {
          this.error = this.$t('We couldn\'t fetch the webpage. Maybe there are anti-fetching-rules in place. Please test another URL.')
          this.progress = 0
          this.genderableWords = ''
          this.fixedWithUrls = ''
          this.queryRunning = false
        }
        const responseTrunc = response.data.result.substring(0, 30000)
        const responseClean = this.cleanUpString(responseTrunc)
        const re = /[^\s']+(?:\s+[^\s']+){0,20}/g
        const chunks = responseClean.match(re)
        const allGenderSpecifics = []
        for (let index = 0; index < chunks.length; index++) {
          this.progress = (index / chunks.length * 100).toFixed(0)
          const response = await DictionaryEntryService.fixRunningText(chunks[index], "['N','Pro']", ['entries'])
          const messageFromBackend = JSON.parse(response.data.result)
          allGenderSpecifics.push(...messageFromBackend.wordReplacer.entries.map(entry => entry.genderSpecific))
          // to ensure uniqueness
          this.genderableWords = [...new Set(allGenderSpecifics)]
          this.fixedWithUrls = '<div class="table-responsive"><table class="table table-striped"><tbody>' + this.genderableWords.map(e => `<tr><th  style="min-width:110px">${e}</th><td style="min-width:110px" scope="row"><a href="${DOMAIN + encodeURI('/dict/' + e)}" target="_blank">Gender&shy;wörter&shy;buch&shy;eintrag <i class="fas fa-link text-secondary small"></i></a></td> <td style="min-width:110px"><a href="${this.urlToAnalyze}#:~:text=${e}" target="_blank">Auf ${this.urlToAnalyzeTrunc} anzeigen <i class="fas fa-external-link-alt text-secondary small"></i><small class="ml-2 text-muted">(nur für Chrome & Edge)</small></a></td></tr>`).join(' ') + '</tbody></table></div>'
        }
        this.queryRunning = false
        // this.queries = newQueries
        this.error = ''
      }
    },
    showRegistrationModal () {
      this.$refs.registerModal.show()
    },
    hideRegistrationModal () {
      this.$refs.registerModal.hide()
    }
  },
  watch: {
    running_text: function () {
      this.error = ''
    }
  },
  mounted () {
    if (!this.isLoggedIn()) {
      this.showRegistrationModal()
    }
  },
  components: { TopNavBar, GenderAppFooter }
}

async function wait (ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}
</script>

<style scoped>

.fixed-hight{
    height: 30vh;
    border-radius: 0.25rem;
    word-break: break-word;
  overflow-y:scroll;
}

.invisible-scrollbar::-webkit-scrollbar {
    display: none !important;
  }
  >>>.text__highlight{
    background: rgba(35, 182, 78, 0.66);
    color: white;
    padding: 1px !important;
  }

.progress-bar-item {
  transition: all 1s;
  display: inline-block;
  /*margin-right: 140px;*/
}
.progress-bar-complete-enter, .progress-bar-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(200px);
}
.progress-bar-complete-leave-active {
  position: absolute;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #58585A;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #464648;
}
</style>

<style>
</style>
